import React from "react"
import SEO from "../components/seo"
import Astronaut from "../components/astronaut"
import Logo from "../components/image"
import { Link } from "gatsby"


const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <section className="grid grid-cols-12">
    <div className="col-start-4 col-span-6 text-center my-6">
    <div className="mx-6" style={{ maxWidth: `250px`, marginRight: `auto`, marginLeft: `auto`, marginBottom: `2em`, padding: `6px 9px`, borderRadius: `5em` }}>
      <Logo />
    </div>
    <h1 className="text-5xl my-6">PAGE NOT FOUND</h1>
    <h2 className="text-2xl mb-3">You just hit a route that doesn&#39;t exist.</h2>
    <Link to="/">
      <button className="text-gray-900 border-2 bg-gray-200 rounded-sm hover:bg-gray-400 border-gray-900 p-3">Take me home</button>
    </Link>
    <Astronaut/>
    </div>
    </section>
  </>
)

export default NotFoundPage
